import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import block from 'bem-cn';

import Spinner from 'components/Spinner/view/desktop';

import FilterBlock from './FilterBlock';
import Table from './Table/Table';

import './Cash.scss';

const b = block('cash');

const Cash = ({ isMobile }) => {
  const { actionProcessing } = useSelector(state => state.reports, shallowEqual);

  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);

  const onItemsOnPageChanged = newItemsOnPage => {
    setCount(newItemsOnPage);
    setPage(0);
  };

  return (
    <div className={b({ isMobile })}>
      <Spinner isLoading={actionProcessing} />
      <FilterBlock count={count} currentPage={page} onPageClick={setPage} isMobile={isMobile} />
      <Table currentPage={page} onPageClick={setPage} onItemsOnPageChanged={onItemsOnPageChanged} isMobile={isMobile} />
    </div>
  );
};

export default Cash;
