import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import asyncPoll from 'react-async-poll';

import { actions as authActions } from 'features/Auth';

const AsyncPollBalance = () => null;

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  const actions = {
    getBalance: authActions.getBalance,
  };
  return bindActionCreators(actions, dispatch);
}

function onPollInterval(props) {
  if (props.auth.isAuth) {
    props.getBalance({});
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(asyncPoll(5 * 1000, onPollInterval)(AsyncPollBalance));
