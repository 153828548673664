import { convertFieldsToSearch } from 'services/helper';
import BaseApi from '../BaseApi';
import { UserApiConverter } from './UserApiConverter';

class UserApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/agent_admin`;
    this.converter = new UserApiConverter();
  }

  createUser = requestBody => this.sendQuery(this.queryTypes.POST, `${this.baseUrl}/user/`, requestBody);

  depositOrWithdrawal = ({ id, operation, amount }) =>
    this.sendQuery(this.queryTypes.POST, `${this.baseUrl}/user/${id}/payment/`, { operation, amount });

  getUsersList = ({ userId, isBanned, ...data }) => {
    return this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/user/${convertFieldsToSearch({
        ...data,
        user_id: userId !== undefined ? String(userId) : null,
        is_banned: isBanned !== undefined ? String(isBanned) : null,
        username: data?.username !== undefined ? String(data.username) : null,
        page: data?.page !== undefined ? String(data.page) : null,
        count: data?.count !== undefined ? String(data.count) : null,
        role: data?.role !== undefined ? String(data.role) : null,
      })}`,
      null,
      null,
      this.converter.convertUsersList,
      { role: data?.role !== undefined ? String(data.role) : null },
    );
  };
  searchUser = ({ username, ...data }) => {
    return this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/user/search/${convertFieldsToSearch({
        username: username,
        ...data,
      })}`,
      null,
      null,
      this.converter.convertUsersListForSearch,
      { role: data.role },
    );
  };

  changeBanState = (id, value) =>
    this.sendQuery(this.queryTypes.POST, `${this.baseUrl}/change_attribute`, {
      operation: 'change_ban',
      target_id: id,
      is_banned: value,
    });

  changeUserBalance = (id, amount, isOutcome) =>
    this.sendQuery(this.queryTypes.POST, `${this.baseUrl}/change_attribute`, {
      operation: 'change_balance',
      target_id: +id,
      amount: +amount,
      is_outcome: isOutcome,
    });

  resetPassword = (id, new_password) =>
    this.sendQuery(
      this.queryTypes.POST,
      `${this.baseUrl}/change_attribute`,
      {
        operation: 'change_password',
        target_id: id,
        new_password: new_password,
      },
      null,
      this.converter.convertNewPassword,
    );

  changeUserInfo = ({ data, id }) => {
    return this.sendQuery(this.queryTypes.PATCH, `${this.baseUrl}/user/${id}/`, data);
  };

  getUserInfo = ({ id }) =>
    this.sendQuery(this.queryTypes.GET, `${this.baseUrl}/user/${id}/`, null, null, this.converter.convertUserInfo);

  setUserPermissions = ({ userId, type, provider = null, isActive, changeSubstructure = false }) =>
    this.sendQuery(this.queryTypes.POST, `${this.baseUrl}/user/${userId}/permission/`, {
      providers: [provider ? provider?.toUpperCase() : type].filter(el => el),
      can_access: isActive,
      change_substructure: changeSubstructure,
    });

  getUserPermissions = userId =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/user/${userId}/permission/`,
      null,
      null,
      this.converter.convertUserPermissions,
    );

  getUserPermissionsByCasinoType = ({ userId, type }) =>
    this.sendQuery(this.queryTypes.GET, `${this.baseUrl}/user/${userId}/permission/${type}/`, null, null, data =>
      this.converter.convertUserPermissionsByType({ data, type }),
    );
}

export default UserApi;
