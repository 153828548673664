import React, { useCallback } from 'react';
import block from 'bem-cn';
import { Link } from 'react-router-dom';

import ListItem from './ListItem';
import logoPNG from 'shared/img/logo.png';
import burgerSvg from './img/burger.svg';
import { actions } from 'features/app';

import './Sidebar.scss';

import { sidebarMenu } from '../../data';
import SVG from 'components/SVG';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

const b = block('sidebar');

const Sidebar = ({ changeMenuOpen = () => null }) => {
  const dispatch = useDispatch();
  const changeMenu = useCallback(() => {
    dispatch(actions.changeSideMenu());
  }, [dispatch]);
  const { sideMenuOpened } = useSelector(state => state.app, shallowEqual);
  return (
    <div className={b({ sideMenuOpened })}>
      <div className={b('top')}>
        <SVG svgProps={{ svg: burgerSvg }} className={b('burger')} onClick={changeMenu} />
        {!sideMenuOpened && (
          <Link className={b('logo-wrapper')} to="/">
            <img src={logoPNG} className={b('logo')} alt="logo" />
          </Link>
        )}
      </div>
      <div className={b('menu')}>
        {sidebarMenu.map(el => (
          <ListItem item={el} key={el.title} changeMenuOpen={changeMenuOpen} />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
