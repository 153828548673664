import React, { useMemo, useEffect } from 'react';
import block from 'bem-cn';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SVG from 'components/SVG';
import arrowSVG from './img/arrow.svg';
import { actions } from 'features/finance';

import './FlatPage.scss';

const b = block('flat-page');
const FlatPage = () => {
  const { page } = useParams();
  const flatPages = useSelector(state => state.finance.flatPages);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(actions.getFlatPages());
  }, []);
  const flatPage = useMemo(() => flatPages.find(el => el.idName === page), [page, flatPages]);

  return (
    <div className={b()}>
      <div className={b('title')}>
        <SVG className={b('icon')} onClick={history.goBack} svgProps={{ svg: arrowSVG }} />
        <span>{flatPage?.name}</span>
      </div>
      <article className={b('content')} dangerouslySetInnerHTML={{ __html: flatPage?.text }} />
    </div>
  );
};

export default FlatPage;
