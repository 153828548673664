import React, { useState, useCallback, useContext, useMemo } from 'react';
import block from 'bem-cn';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ModalContext } from 'components/ModalProvider/ModalProvider';

import Button from 'components/Button';
import Input from 'components/Input';
import { actions } from 'features/users/redux';

import './CreatePlayer.scss';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import { phoneCodes } from 'components/PhoneInput/PhoneInput';
import InputDate from 'components/InputDate/desktop';

import { validatePassword } from 'shared/helpers/validatePassword';

const b = block('create-player');

const CreatePlayer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { openModal, closeModal } = useContext(ModalContext);
  const {
    passwordSettings: { player },
  } = useSelector(state => state.userSettings);

  const [form, setForm] = useState({
    username: '',
    birth_date: '',
    nameAndSurname: '',
    password: '',
    confirmPassword: '',
    phone: '',
  });

  const isNewPasswordValid = useMemo(() => {
    return validatePassword(form.password, player) && form.password !== '';
  }, [form.password, player]);

  const isConfirmPasswordValid = useMemo(() => {
    return validatePassword(form.confirmPassword, player) && form.confirmPassword === form.password;
  }, [form.confirmPassword, form.password, player]);

  const onSubmit = useCallback(() => {
    closeModal();
    dispatch(actions.createUser({ form, callback: () => history.push('/users/all') }));
  }, [closeModal, dispatch, form, history]);

  const openPopUp = useCallback(
    e => {
      e.preventDefault();
      openModal({
        content: (
          <div className={b('confirmation-pop-up')}>
            <div
              className={b('confirmation-pop-up', 'description')}
              dangerouslySetInnerHTML={{
                __html: `${locale.wantToCreateAgent} <span> ${form.username}</span>.`,
              }}
            />
            <div className={b('confirmation-pop-up', 'buttons')}>
              <Button color="transparent" size="low" callBack={onSubmit} text={locale.createPlayer} />
              <Button callBack={closeModal} text={locale.cancel} size="low" />
            </div>
          </div>
        ),
      });
    },
    [openModal, locale.wantToCreateAgent, locale.createPlayer, locale.cancel, form.username, onSubmit, closeModal],
  );

  const changeValue = useCallback(
    e => {
      setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value?.replace(/\s/g, '') });
    },
    [form],
  );

  const changePhoneNumber = useCallback(
    value => {
      setForm({ ...form, phone: value });
    },
    [form],
  );
  const changeDob = useCallback(
    value => {
      setForm({ ...form, birth_date: value });
    },
    [form],
  );

  const changeNameAndSurname = useCallback(
    e => {
      setForm({ ...form, nameAndSurname: e.currentTarget.value });
    },
    [form],
  );

  const changeValueUsername = useCallback(
    e => setForm({ ...form, username: e.currentTarget.value?.replace(/\s/g, '') }),
    [form],
  );

  const disabled = !(isNewPasswordValid && isConfirmPasswordValid) || form.username.length === 0;

  return (
    <div className={b()}>
      <form className={b('form')} onSubmit={openPopUp} autoComplete="off">
        <div className={b('header')}>{locale.createPlayer}</div>
        <div className={b('fields')}>
          <div className={b('form', 'input-block')}>
            <div className={b('input')}>
              <span className={b('form', 'input-block-title')}>
                {locale.username}
                <span className={b('star')}>*</span>
              </span>
              <Input
                validState={form.username.length > 0 ? 1 : 3}
                placeholder={locale.username}
                onChange={changeValueUsername}
                value={form.username}
              />
            </div>
          </div>
          {/*           <div className={b('form', 'input-block')}>
            <div className={b('input')}>
              <span className={b('form', 'input-block-title')}>{locale.dob}</span>
              <InputDate withManualTyping needPadding selected={form.birth_date} onChange={changeDob} isBirthday />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>{locale.nameAndSurname}</span>
            <div className={b('input')}>
              <Input placeholder={locale.nameAndSurname} onChange={changeNameAndSurname} value={form.nameAndSurname} name="nameAndSurname" />
            </div>
          </div> */}

          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>
              {locale.password}
              <span className={b('star')}>*</span>
            </span>
            <div className={b('input')}>
              <Input
                placeholder={locale.password}
                withEye
                validState={isNewPasswordValid ? 1 : 3}
                type="password"
                onChange={changeValue}
                value={form.password}
                name="password"
                autocomplete="new-password"
              />
            </div>
          </div>

          {/*           <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>{locale.phone}</span>
            <div className={b('input')}>
              <PhoneInput
                changePhoneNumber={changePhoneNumber}
                phone={form.phone}
                countryCode={phoneCodes.ARGENTINA.code}
              />
            </div>
          </div> */}

          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>
              {locale.confirmPassword}
              <span className={b('star')}>*</span>
            </span>
            <div className={b('input')}>
              <Input
                placeholder={locale.confirmPassword}
                onChange={changeValue}
                value={form.confirmPassword}
                name="confirmPassword"
                withEye
                type="password"
                autocomplete="new-password"
                validState={isConfirmPasswordValid ? 3 : 1}
              />
            </div>
          </div>
        </div>

        <div className={b('bottom')}>
          <Button color="full-transparent" callBack={history.goBack} text={locale.cancel} size="low" />
          <Button disabled={disabled} type="submit" size="low" text={locale.createPlayer} />
        </div>
      </form>
    </div>
  );
};

export default CreatePlayer;
