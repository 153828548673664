import React from 'react';
import block from 'bem-cn';
import './Switcher.scss';

const b = block('switcher');

const Switcher = ({ isActive, onSwitch }) => {
  return (
    <div className={b({ active: isActive })} onClick={() => onSwitch(!isActive)}>
      <div className={b('indicator')} />
    </div>
  );
};

export default Switcher;
