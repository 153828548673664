import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';

import { formatNumberWithLimit } from 'shared/helpers/formatNumber';

import './TableRow.scss';

const b = block('player-report-table-row-pagination');

const TableRow = ({ item }) => {
  return (
    <div className={b('tr')}>
      <div className={b('td')}>{item.id}</div>
      <div className={b('td')}>{item.name}</div>
      <div className={b('td')}>{formatNumberWithLimit(item.amountIn)}</div>
      <div className={b('td')}>{formatNumberWithLimit(item.amountOut)}</div>
      <div className={b('td')}>{formatNumberWithLimit(item.total)}</div>
    </div>
  );
};

TableRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    amountIn: PropTypes.number.isRequired,
    amountOut: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
};

export default TableRow;
