import BaseApi from '../BaseApi';
import SettingsApiConverter from './SettingsApiConverter';

class SettingsApi extends BaseApi {
  converter;
  validator;

  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api`;
    this.converter = new SettingsApiConverter();
  }

  getSettings = () =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/site/settings`,
      undefined,
      undefined,
      data => this.converter.convertSettings({ data }),
    );
}

export default SettingsApi;
