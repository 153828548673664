import changePasswordSVG from '../img/change-password.svg';
import disableSVG from '../img/disable.svg';
import editSVG from '../img/edit.svg';
import informationsSVG from '../img/informations.svg';
import agentSVG from '../img/agent.svg';
import cashierAgentSVG from '../img/cashier-agent.svg';
import playerSVG from '../img/player.svg';
import lastMovementsSVG from '../img/last-movements.svg';
import changePermissionsSVG from '../img/change-permissions.svg';

export const userTypes = {
  0: {
    icon: playerSVG,
    type: 'player',
  },
  1: {
    icon: cashierAgentSVG,
    type: 'cashierAgent',
  },
  6: {
    icon: agentSVG,
    type: 'agent',
  },
  7: {
    icon: cashierAgentSVG,
    type: 'cashierAgent',
  },
};

export const actionItems = {
  forEnabled: [
    {
      svg: informationsSVG,
      title: 'informations',
      link: '/user/info',
    },
    {
      svg: editSVG,
      title: 'edit',
      link: '/user/edit',
    },
    {
      svg: changePasswordSVG,
      title: 'changePassword',
      link: '/user/change-password',
    },
    {
      svg: lastMovementsSVG,
      title: 'lastMovements',
    },
    {
      svg: disableSVG,
      title: 'disable',
      link: '/user/disable',
    },
    {
      svg: changePermissionsSVG,
      title: 'changePermissions',
    },
  ],
  forDisabled: [
    {
      svg: disableSVG,
      title: 'enable',
      link: '/user/enable',
    },
    {
      svg: informationsSVG,
      title: 'informations',
      link: '/user/info',
    },
  ],
};

export const getActionItems = ({ role, isBanned }) => {
  if (isBanned) {
    return [
      {
        svg: disableSVG,
        title: 'enable',
        link: '/user/enable',
      },
      {
        svg: informationsSVG,
        title: 'informations',
        link: '/user/info',
      },
    ];
  }
  if (Number(role) === 0) {
    return [
      {
        svg: informationsSVG,
        title: 'informations',
        link: '/user/info',
      },
      // {
      //   svg: editSVG,
      //   title: 'edit',
      //   link: '/user/edit',
      // },
      {
        svg: changePasswordSVG,
        title: 'changePassword',
        link: '/user/change-password',
      },
      {
        svg: changePermissionsSVG,
        title: 'permissionsDetails',
        link: '/user/permissionsDetails',
      },
      {
        svg: lastMovementsSVG,
        title: 'lastMovements',
      },
      {
        svg: disableSVG,
        title: 'disable',
        link: '/user/disable',
      },
    ];
  }
  if (Number(role) === 6 || Number(role) === 7 || Number(role) === 1) {
    return [
      {
        svg: informationsSVG,
        title: 'informations',
        link: '/user/info',
      },
      {
        svg: editSVG,
        title: 'edit',
        link: '/user/edit',
      },
      {
        svg: changePasswordSVG,
        title: 'changePassword',
        link: '/user/change-password',
      },
      {
        svg: changePermissionsSVG,
        title: 'permissionsDetails',
        link: '/user/permissionsDetails',
      },
      {
        svg: lastMovementsSVG,
        title: 'lastMovements',
      },
      {
        svg: disableSVG,
        title: 'disable',
        link: '/user/disable',
      },
    ];
  }
};

export const permissionsDetailsData = [
  {
    category: 'slots',
    categoryName: 'casino',
    items: [
      'PRAGMATIC-SLOTS',
      'PRAGMATIC-NEW',
      'ECONOMIX',
      'EVOPLAY',
      'SLOTEGRATOR',
      // 'SOFTGAMING-SLOTS',
      'BSW',
      'INBET',
      'FLG',
      'RUBYPLAY',
      'CASIMI',
      'FIABLE',
    ],
  },
  {
    category: 'live-casino',
    categoryName: 'liveCasino',
    items: ['TVBET', 'XPG', 'PRAGMATIC-LIVE-CASINO'],
  },
  {
    category: 'virtual-games',
    categoryName: 'virtualGames',
    items: ['GOLDENRACE'],
  },
  { category: 'sport', categoryName: 'sport', items: ['DIGITAIN'] },
];

export const amountForAutoInput = [100, 500, 1000, 2500, 5000, 10000];

const numberFormat = new Intl.NumberFormat('ru-RU', { style: 'decimal' });
export const amountForAutoInputFormatted = amountForAutoInput.map(value => ({
  value,
  renderElement: numberFormat.format(value),
}));
