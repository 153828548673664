import React from 'react';
import block from 'bem-cn';
import PT from 'prop-types';
import { NumericFormat } from 'react-number-format';

import './InputMoney.scss';

const b = block('input-money');

const InputMoney = ({
  value,
  onChange,
  placeholder,
  validState = 1,
  name,
}) => {

  return (
    <NumericFormat
      type="text"
      value={value}
      decimalSeparator="."
      thousandsGroupStyle="thousand"
      thousandSeparator=","
      decimalScale={2}
      onChange={onChange}
      className={b({ validState })}
      placeholder={placeholder}
      name={name}
      allowNegative
    />
  )
};

InputMoney.propTypes = {};

export default InputMoney;
