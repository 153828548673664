export const formatNumberWithLimit = (number) => {
  const value = Number(number);
  if (isNaN(value)) return number;
  return Math.abs(value) < 1000
    ? value.toFixed(2)
    : value.toLocaleString('ru-RU', { minimumFractionDigits: 2 }).replace(/,/g, '.');
};

export const reformatNumber = number => {
  return +number.toString().replace(/,/g, '');
}
