import { actionTypes } from './actions';
import initialState from './initial';

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CHANGE_MENU: {
      return {
        ...state,
        sideMenuOpened: !state.sideMenuOpened,
      };
    }

    default:
      return { ...state };
  }
}

export default reducer;
