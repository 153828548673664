import React, { createContext, useState } from 'react';

import Modal from './Modal/Modal';

export const ModalContext = createContext({
  openModal: modalConfig => {},
  changeModal: modalConfig => {},
  closeModal: () => {},
});

export const ModalProvider = ({ children, isMobile = false }) => {
  const [isModal, setIsModal] = useState(false);
  const [modal, setModal] = useState({});

  const openModal = modalConfig => {
    setModal(modalConfig);
    setIsModal(true);
  };
  const closeModal = () => {
    setIsModal(false);
  };
  const changeModal = modalConfig => {
    closeModal();
    openModal(modalConfig);
  };

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
        changeModal,
      }}>
      {isModal && <Modal {...modal} isMobile={isMobile} />}
      {children}
    </ModalContext.Provider>
  );
};
