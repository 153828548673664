const currentYear = new Date().getFullYear();
const minPermittedAge = 14; // Минимальный допустимый возраст для создания пользователя (14 лет)
const minBirthYear = currentYear - minPermittedAge;

export const birthYears = Array.from({ length: 100 }, (_, i) => minBirthYear - i);

export const months = [
	"january",
	"february",
	"march",
	"april",
	"may",
	"june",
	"july",
	"august",
	"september",
	"october",
	"november",
	"december",
];

export const getYear = (date) => {
	return date.getFullYear();
};

export const getMonth = (date) => {
	return date.getMonth();
};