import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import Select from 'components/Select/desktop';
import Button from 'components/Button';
import InputDate from 'components/InputDate/desktop';
import Input from 'components/Input';

import DaysFilter from 'features/reports/view/desktop/DaysFilter';
import useTree from 'hooks/useTree';

import { actions } from 'features/reports/redux';

import './FilterBlock.scss';
import { statusSportBets, typesSportBets } from 'features/reports/data';

const b = block('filter-block-player-sport');

const FilterBlock = ({ count, currentPage, onPageClick }) => {
  const { getTreeById } = useTree();

  const locale = useSelector(state => state.locale.locale);
  const dispatch = useDispatch();
  const [date, setDate] = useState({
    beginDate: new Date(dayjs().add(-1, 'week')),
    endDate: new Date(dayjs().add(1, 'day')),
  });
  const [activeDay, setActiveDay] = useState('');
  const [filters, setFilters] = useState({
    userId: '',
    betId: '',
    statusBet: statusSportBets[0],
    typeSport: typesSportBets[0],
  });

  const handleUserChange = useCallback(
    e => {
      setFilters({ ...filters, [e.currentTarget.name]: e.currentTarget.value.toLowerCase() });
    },
    [filters],
  );
  const handleBetIdChange = useCallback(
    e => {
      setFilters({ ...filters, [e.currentTarget.name]: e.currentTarget.value.toLowerCase() });
    },
    [filters],
  );

  const handleStatusBetChange = useCallback(
    value => {
      const statusBet = statusSportBets.find(item => item.value === value);
      setFilters({ ...filters, statusBet });
    },
    [filters],
  );

  const handleTypesBetChange = useCallback(
    value => {
      const typesBet = typesSportBets.find(item => item.value === value);
      setFilters({ ...filters, typeSport: typesBet });
    },
    [filters],
  );

  const handleSearch = useCallback(
    upperAgent => {
      const requestedData = {
        userId: filters.userId,
        betId: filters.betId,
        type: filters.typeSport.value,
        count,
        status: filters.statusBet.value,
        page: currentPage,
        gameType: 'sport',
        ...date,
      };
      dispatch(actions.getBetsHistory(requestedData));
    },
    [count, currentPage, date, dispatch, filters],
  );

  const onClickSearch = withoutZeroPage => {
    getTreeById('').then(upperAgent => handleSearch(upperAgent));
    if (!withoutZeroPage) onPageClick(0);
  };

  const handleBeginDateChange = useCallback(
    value => {
      if (!isNaN(value.valueOf())) {
        setDate({ ...date, beginDate: value });
      }

      setActiveDay('');
    },
    [date],
  );

  const handleEndDateChange = useCallback(
    value => {
      if (!isNaN(value.valueOf())) {
        setDate({ ...date, endDate: value });
      }
    },
    [date],
  );

  const onChangeActiveDay = useCallback(
    dayFilter => {
      setActiveDay(dayFilter);
      setDate({
        ...date,
        beginDate: new Date(dayjs().add(dayFilter.value, 'day')),
        endDate: dayFilter.value === -1 ? new Date(dayjs()) : new Date(dayjs().add(1, 'day')),
      });
    },
    [date],
  );

  useEffect(() => {
    onClickSearch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, currentPage]);

  return (
    <div className={b()}>
      <div className={b('header')}>{locale.filter}</div>
      <div className={b('top')}>
        <DaysFilter activeDay={activeDay} setActiveDay={onChangeActiveDay} />
        <div className={b('or')}>or</div>
        <div className={b('date')}>
          <div className={b('date-row')}>
            <div className={b('title')}>{locale.dateFrom}</div>
            <div className={b('date-input')}>
              <InputDate selected={date.beginDate} onChange={handleBeginDateChange} />
            </div>
          </div>
          <div className={b('date-row')}>
            <div className={b('title')}>{locale.dateTo}</div>
            <div className={b('date-input')}>
              <InputDate selected={date.endDate} onChange={handleEndDateChange} />
            </div>
          </div>
        </div>
      </div>
      <div className={b('bottom')}>
        <div className={b('inputs')}>
          <div className={b('input')}>
            <div className={b('title')}>{locale.betId}</div>
            <Input
              size="low"
              value={filters.betId}
              callBack={handleBetIdChange}
              placeholder={locale.betId}
              name="betId"
            />
          </div>
          <div className={b('input')}>
            <div className={b('title')}>{locale.userId}</div>
            <Input
              size="low"
              value={filters.userId}
              callBack={handleUserChange}
              placeholder={locale.userId}
              name="userId"
            />
          </div>
          <div className={b('input')}>
            <div className={b('title')}>{locale.type}</div>
            <Select items={typesSportBets} activeItem={filters.typeSport} onChange={handleTypesBetChange} />
          </div>
          <div className={b('input')}>
            <div className={b('title')}>{locale.result}</div>
            <Select items={statusSportBets} activeItem={filters.statusBet} onChange={handleStatusBetChange} />
          </div>
        </div>
        <div className={b('button')}>
          <Button size="low" callBack={onClickSearch} text={locale.acceptFilter} />
        </div>
      </div>
    </div>
  );
};

export default FilterBlock;
