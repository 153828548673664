import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';

import { formatNumberWithLimit } from 'shared/helpers/formatNumber';

import './TableRow.scss';

const TableRow = ({ item, locale }) => {
  const b = block('table-row-cash-finance');

  return (
    <div className={b('tr')}>
      <div className={b('td')}>
        <span className={b('td-el')}>{item.id}</span>
      </div>
      <div className={b('td')}>
        <span className={b('td-el')}>{item.date}</span>
      </div>
      <div className={b('td')}>
        <span className={b('td-el')}>{item.fromUser}</span>
      </div>
      <div className={b('td')}>
        <span className={b('td-el')}>{item.toUser}</span>
      </div>
      <div className={b('td')}>
        <span className={b('td-el')}>{item.transitionType}</span>
      </div>
      <div className={b('td')}>
        <span className={b('td-el')}>{formatNumberWithLimit(item.amount)}</span>
      </div>
      <div className={b('td')}>
        <span className={b('td-el')}>{formatNumberWithLimit(item.balanceBefore)}</span>
      </div>
      <div className={b('td')}>
        <span className={b('td-el')}>{formatNumberWithLimit(item.balanceAfter)}</span>
      </div>
    </div>
  );
};

TableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TableRow;
