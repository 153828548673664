export const validatePassword = (value, passwordSettings) => {
  // const regExp1 = new RegExp(`^[\\w]+$`, 'ig');
  // const correctSymbols = regExp1.test(value);

  const correctLength = value.length >= passwordSettings.min_length;

  const regExp2 = new RegExp(`\\d`, 'ig');
  const symbols1 = value.match(regExp2);
  const hasAtLeastNumber =
    symbols1 !== null ? symbols1.length >= passwordSettings.digits.amount : passwordSettings.digits.amount === 0;

  const regExp3 = new RegExp(`[a-z]`, 'ig');
  const symbols2 = value.match(regExp3);
  const hasAtLeastLetters =
    symbols2 !== null
      ? symbols2.length >= passwordSettings.letters.lowercase
      : passwordSettings.letters.lowercase === 0;
  const regExp4 = new RegExp(`[A-Z]`, 'g');
  const symbols3 = value.match(regExp4);
  const hasAtLeastUppercaseLetters =
    symbols3 !== null
      ? symbols3.length >= passwordSettings.letters.uppercase
      : passwordSettings.letters.uppercase === 0;

  const letterLength =
    symbols3 !== null && symbols2 !== null
      ? symbols3.length + symbols2.length >= passwordSettings.letters.amount
      : passwordSettings.letters.amount === 0;

  const regExp5 = new RegExp('[^\\p{L}\\p{N}\\s]', 'gu');
  const symbols4 = value.match(regExp5);

  const hasAtLeastSpecialSymbols =
    symbols4 !== null
      ? symbols4.length >= passwordSettings.special_characters.amount
      : passwordSettings.special_characters.amount === 0;

  return (
    // correctSymbols &&
    correctLength &&
    hasAtLeastNumber &&
    hasAtLeastLetters &&
    hasAtLeastUppercaseLetters &&
    letterLength &&
    hasAtLeastSpecialSymbols
  );
};
