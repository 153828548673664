import React, { useState, useEffect } from 'react';
import block from 'bem-cn';
import Input from 'components/Input';

import './SearchInput.scss';

const b = block('search-input-desktop');

const SearchInput = ({ value, onChange, placeholder, items, onKeyDown }) => {
	const [searchText, setSearchText] = useState(value);
	const [isOpen, setIsOpen] = useState(false);
  
	useEffect(() => {
	  setSearchText(value);
	}, [value]);
  
	const handleChange = event => {
	  const { value } = event.target;
	  setSearchText(value);
	  onChange(value);
	  setIsOpen(true);
	};
  
	const itemsList = items?.map(item => {
	  return (
		<li
		  key={item.username}
		  className={b('item')}
		  onClick={() => {
			setSearchText(item.username);
			onChange(item.username);
			setIsOpen(false);
		  }}
		>
		  {item.username}
		</li>
	  );
	});
  
	return (
	  <div className={b()}>
		<Input
		  onChange={handleChange}
		  value={searchText}
		  placeholder={placeholder}
		  onKeyDown={onKeyDown}
		/>
		{isOpen && items?.length > 0 && searchText && (
		  <div className={b('items-list')}>{itemsList}</div>
		)}
	  </div>
	);
  };

export default SearchInput;