import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectUserInfoById } from '../redux';
import { useEffect, useMemo } from 'react';

export function useInformationModel() {
  const dispatch = useDispatch();

  const { userId } = useParams();
  const userInfo = useSelector(selectUserInfoById(userId));
  const { actionProcessing } = useSelector(state => state.users);

  const agentId = useMemo(() => userInfo?.parentAgentId, [userInfo]);
  const agentInfo = useSelector(selectUserInfoById(agentId));

  useEffect(() => {
    dispatch(actions.getUserInfo({ id: userId }));
  }, [dispatch, userId]);

  useEffect(() => {
    if(agentId !== undefined) dispatch(actions.getUserInfo({ id: agentId }));
  }, [dispatch, agentId]);

  useEffect(() => {
    dispatch(
      actions.getUsersList({
        userId: userId,
        role: 0,
        count: 1,
        page: 0,
      }),
    );
  }, [dispatch, userId]);

  return {
    userInfo,
    agentInfo,
    actionProcessing,
    isLoaded: Boolean(userInfo) && Boolean(agentInfo),
  };
}
