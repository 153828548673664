import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'components/Button';
import InputDate from 'components/InputDate/desktop';
import DaysFilter from 'features/reports/view/desktop/DaysFilter';

import { actions } from 'features/finance';

import './FilterBlock.scss';

const b = block('filter-block-my-debt');

const FilterBlock = ({ count, currentPage, onPageClick }) => {
  const locale = useSelector(state => state.locale.locale);
  const { selectedAgentId } = useSelector(state => state.tree);

  const dispatch = useDispatch();
  const [date, setDate] = useState({
    beginDate: new Date(dayjs().add(-1, 'week')),
    endDate: new Date(dayjs().add(1, 'day')),
  });
  const [activeDay, setActiveDay] = useState('');

  const onChangeBeginDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, beginDate: value });
    }
    setActiveDay('');
  };

  const onChangeEndDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, endDate: value });
    }
  };

  const onClickSearch = () => {
    onPageClick(0);
    handleSearch();
  };

  const handleSearch = useCallback(() => {
    const requestedData = {
      count,
      page: currentPage,
      ...date,
    };
    dispatch(actions.getFinanceHistory(requestedData));
  }, [count, currentPage, date, dispatch]);

  const onChangeActiveDay = useCallback(
    dayFilter => {
      setActiveDay(dayFilter);
      setDate({
        ...date,
        beginDate: new Date(dayjs().add(dayFilter.value, 'day')),
        endDate: dayFilter.value === -1 ? new Date(dayjs()) : new Date(dayjs().add(1, 'day')),
      });
    },
    [date],
  );

  useEffect(() => {
    if (selectedAgentId) {
      handleSearch();
    }
  }, [count, currentPage, selectedAgentId]);

  return (
    <div className={b()}>
      <div className={b('header')}>{locale.filter}</div>
      <div className={b('wrapper')}>
        <div className={b('date-container')}>
          <DaysFilter activeDay={activeDay} setActiveDay={onChangeActiveDay} />
          <div className={b('or')}>or</div>
          <div className={b('date')}>
            <div className={b('date-row')}>
              <div className={b('title')}>{locale.dateFrom}</div>
              <div className={b('date-input')}>
                <InputDate selected={date.beginDate} onChange={onChangeBeginDate} />
              </div>
            </div>
            <div className={b('date-row')}>
              <div className={b('title')}>{locale.dateTo}</div>
              <div className={b('date-input')}>
                <InputDate selected={date.endDate} onChange={onChangeEndDate} />
              </div>
            </div>
          </div>
        </div>
        <div className={b('button')}>
          <Button size="low" callBack={() => onClickSearch()} text={locale.acceptFilter} />
        </div>
      </div>
    </div>
  );
};

export default FilterBlock;
