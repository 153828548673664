import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { shallowEqual, useSelector } from 'react-redux';

import { formatNumberWithLimit } from 'shared/helpers/formatNumber';
import { useSportBetsListDesktop } from '../../../../features/reports/hooks/useLists';

import SVG from 'components/SVG';
import PlayerSVG from './img/player.svg';

import './TableRow.scss';

const b = block('table-row-pagination');

const TableRow = ({ type, item }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const getFilteredItem = useMemo(() => {
    switch (type) {
      case 'sport-bets':
        return useSportBetsListDesktop;
      default:
        return () => [];
    }
  }, [type]);

  const list = getFilteredItem(item);
  const filteredItems = list.items ? list.items : Object.values(item);
  const headerItems = list.headers ? list.headers : Object.keys(item);

  return (
    <div className={b('tr')}>
      {filteredItems?.map((el, index) => {
        const isHistory = headerItems[index] === 'status' || headerItems[index] === 'type';
        const isUser = headerItems[index] === 'userId';
        if (typeof el === 'object' && el.length > 0) {
          return (
            <div key={`${el}_${index}`} className={b('td', { status: isHistory && el })}>
              <div className={b('user-block')}>
                {el.map((part, partIndex) => (
                  <span key={`${part}_${partIndex}`}>
                    {el[0] === part ? `${locale.id}: ${part}` : part}
                  </span>
                ))}
              </div>
            </div>
          );
        } else {
          if (isUser) {
            return (
              <div key={`${el}_${index}`} className={b('td', { userId: isUser })}>
                <SVG
                  className={b('player-icon')}
                  svgProps={{ svg: PlayerSVG }}
                />
                <div className={b('user-block')}>
                  <span className={b('user-name')} style={{ wordWrap: 'break-word' }}>{isHistory ? locale.statuses[el] : index > 1 ? formatNumberWithLimit(el) : el}</span>
                  <span className={b('user-type')}>{locale.player}</span>
                </div>
              </div>
            );
          } else {
            return (
              <div key={`${el}_${index}`} className={b('td', { status: isHistory && el })}>
                <span style={{ wordWrap: 'break-word' }}>{isHistory ? locale.statuses[el] : index > 1 ? formatNumberWithLimit(el) : el}</span>
              </div>
            );
          }

        }
      })}
    </div>
  );
};

TableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TableRow;
