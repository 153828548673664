import React from 'react';
import block from 'bem-cn';
import { Link, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import SVG from 'components/SVG';

import { tabLinks } from './data';

import './Tabs.scss';

const b = block('tabs');

const Tabs = ({ role }) => {
  const location = useLocation();
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  return (
    <div className={b()}>
      {tabLinks.map(el => {
        if (el.access && el.access !== role) {
          return
        }
        return (
          <Link to={el.link} key={el.name} className={b('item', { active: location.pathname.includes(el.link) })}>
            <SVG className={b('item-icon')} svgProps={{ svg: el.icon }} />
            <div className={b('item-text')}>{locale[el.name] ?? el.name}</div>
          </Link>
        )
      })}
    </div>
  );
};

export default Tabs;
