import React from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import { formatNumberWithLimit } from 'shared/helpers/formatNumber';

import './Table.scss';

const b = block('reports-table-general');

const Table = ({ items }) => {
  const locale = useSelector(state => state.locale.locale);
  const { currency } = useSelector(state => state.auth);

  return (
    <div className={b()}>
      <div className={b('header')}>{locale.total}</div>
      <div className={b('table')}>
        {Object.entries(items)
          .map(([label, value], idx) => {
            if (label === 'last_calculate_time') return null;
            const isBetCount = label === 'total_bet_count';

            return (
              <div className={b('table-tr')}>
                <div className={b('table-th')}>{locale[label]}</div>
                <div className={b('table-td')}>
                  <div className={b('table-td-amount')}>{!isBetCount ? formatNumberWithLimit(value) : value}</div>
                  {!isBetCount && <div className={b('table-td-currency')}>{currency}</div>}
                </div>
              </div>
            )
        })}
      </div>
    </div>
  );
};

Table.propTypes = {};

export default Table;
