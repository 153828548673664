import allUsersSVG from '../img/allUsers.svg';
import agentsSVG from '../img/agents.svg';
import playersSVG from '../img/players.svg';
import disabledUserSVG from '../img/disabledUser.svg';
import cashierSVG from '../img/cashier.svg';


export const tabLinks = [
  {
    name: 'allUsers',
    link: '/users/all',
    icon: allUsersSVG,
  },
  {
    name: 'agents',
    link: '/users/agents',
    icon: agentsSVG,
    access: 6 // только для агентов

  },
  {
    name: 'cashierAgent',
    link: '/users/cashiers',
    icon: cashierSVG,
    access: 6 // только для агентов

  },
  {
    name: 'players',
    link: '/users/players',
    icon: playersSVG,
    access: 6 // только для агентов
  },
  {
    name: 'disabled',
    link: '/users/disabled',
    icon: disabledUserSVG,


  },
];
