import { actionTypes } from './actions';
import { initialState } from './initial';

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ACTION_PROCESSING:
      return {
        ...state,
        actionProcessing: action.payload,
      };
    case actionTypes.GET_FINANCE_HISTORY:
      return {
        ...state,
        financeHistory: action.payload,
        actionProcessing: false,
      };

    case actionTypes.GET_FLAT_PAGES:
      return {
        ...state,
        flatPages: action.payload,
        actionProcessing: false,
      };

    default:
      return { ...state };
  }
}
