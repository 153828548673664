const actionTypes = {
  GET_SETTINGS: 'userSettings/GET_SETTINGS',
};

const getSettings = () => async (dispatch, getState, extra) => {
  const { api } = extra;
  const response = await api.settings.getSettings();
  if (response.success) {
    dispatch({
      type: actionTypes.GET_SETTINGS,
      payload: response.data,
    });
  }
};

export { getSettings, actionTypes };
