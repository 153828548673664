import React from 'react';
import block from 'bem-cn';
import PT from 'prop-types';
// import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// import { actions as authActions } from 'features/Auth';
import { formatNumberWithLimit } from 'shared/helpers/formatNumber';
import crossSVG from 'shared/img/cross.svg';

import './NotificationItem.scss';

dayjs.extend(customParseFormat);

const b = block('notification-item');

const NotificationItem = ({ id, date, type, amount, fromName, isNew, locale, onCrossClick }) => {
  const formattedDate = dayjs(date, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm');
  const roleMessage =
    type === 0 ? locale.depositMessage(fromName, locale.agent) : locale.withdrawalMessage(fromName, locale.agent);

  return (
    <div className={b('item')}>
      <div className={b('item-wrapper')}>
        <div className={b('item-left')}>
          {isNew && <div className={b('new')}>{locale.new}</div>}
          <p className={b('type')}>{locale.paymentStatuses[type]}</p>
        </div>
        <div className={b('item-right')}>
          <p className={b('date')}>{formattedDate}</p>
          <SVGInline
            className={b('cross', { 'without-margin': true }).toString()}
            svg={crossSVG}
            onClick={() => onCrossClick(id)}
          />
        </div>
      </div>
      <div className={b('item-wrapper')}>
        <p className={b('name')}>
          {roleMessage} <span className={b('amount')}>{formatNumberWithLimit(amount)}</span>
        </p>
      </div>
    </div>
  );
};

NotificationItem.propTypes = {
  id: PT.number.isRequired,
  date: PT.string.isRequired,
  type: PT.number.isRequired,
  amount: PT.number.isRequired,
  fromName: PT.string.isRequired,
  isNew: PT.bool,
  locale: PT.object.isRequired,
  onCrossClick: PT.func.isRequired,
};

export default NotificationItem;
