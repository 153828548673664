import React, { useCallback } from 'react';
import { useAsync } from 'react-async';

import SVG from 'components/SVG';

import { getIconByName } from './data';

const ProviderIcon = ({ className = '', name, type = 'lightSmall' }) => {
  const asyncCallBack = useCallback(async () => {
    return getIconByName({ name, type });
  }, [name, type]);

  const { data, error, isPending } = useAsync(asyncCallBack);

  if (!isPending && !error && data) {
    if (data.ext === 'svg') return <SVG className={className} svgProps={{ svg: data.icon }} />;
    return <img className={className} src={data.icon} alt="" />;
  }
  return null;
};

export default ProviderIcon;
