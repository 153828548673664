import React from 'react';
import block from 'bem-cn';

import SVG from 'components/SVG';
import ClipboardSVG from 'shared/img/clipboard.svg'
import { copyToClipboard } from 'shared/helpers/copyToClipboard';

import './CopyToClipboard.scss';

const b = block('copy-to-clipboard');

const CopyToClipboard = ({ text }) => {
  const handleClipboard = () => {
    copyToClipboard(text)
  }

  return <SVG className={b()} svgProps={{ svg: ClipboardSVG }} onClick={handleClipboard} />;
}

export default CopyToClipboard;