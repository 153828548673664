import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from 'components/Spinner/view/desktop';
import Button from 'components/Button';
import SVG from 'components/SVG';
import { actions } from 'features/users/redux';
import { formatNumberWithLimit } from 'shared/helpers/formatNumber';
import { userTypes } from '../../../../data';
import attentionSVG from '../img/attention.svg';

import './DisableUser.scss';

const b = block('disable-user');

const DisableUser = ({ isDisable = false }) => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const history = useHistory();
  const locale = useSelector(state => state.locale.locale);

  const { userInfo, actionProcessing } = useSelector(state => state.users);

  useEffect(() => {
    dispatch(actions.getUserInfo({ id: userId }));
  }, [dispatch, userId]);

  const onSubmit = useCallback(() => {
    dispatch(
      actions.disableUser({ data: { is_banned: isDisable }, id: userId, callback: () => history.push('/users/all') }),
    );
  }, [dispatch, isDisable, userId, history]);

  if (actionProcessing) return <Spinner isLoading={actionProcessing} />;

  return (
    <div className={b()}>
      <div className={b('wrapper')}>
        <div className={b('top')}>
          <div className={b('header')}>
            <SVG svgProps={{ svg: attentionSVG }} className={b('header-icon')} />
            <div className={b('header-title')}>{locale[isDisable ? 'disableUser' : 'enableUser']}</div>
            <div
              className={b('header-description')}
              dangerouslySetInnerHTML={{
                __html: `${locale[isDisable ? 'disableDescription' : 'enableDescription']} <span> ${
                  userInfo.username
                }</span>.`,
              }}
            />
          </div>
          <div className={b('user-block')}>
            <div className={b('user-block-left')}>
              <SVG svgProps={{ svg: userTypes[userInfo.role].icon }} className={b('user-block-icon')} />
              <div className={b('user-block-info')}>
                <span>{userInfo.username}</span>
                <div className={b('user-block-info-type', { role: userInfo.role })}>
                  {userTypes[userInfo.role].type}
                </div>
              </div>
            </div>
            <div className={b('user-block-right')}>
              <span>ID:</span>
              <span>{userId}</span>
            </div>
            <div className={b('user-block-right')}>
              <span>{locale.balance}:</span>
              <span>{formatNumberWithLimit(userInfo.balance)}</span>
            </div>
          </div>
        </div>
        <div className={b('bottom')}>
          <Button callBack={history.goBack} text={locale.cancel} size="low" />
          <Button
            color="full-transparent"
            callBack={onSubmit}
            type="submit"
            size="low"
            text={locale[isDisable ? 'acceptDisableUser' : 'acceptEnableUser']}
          />
        </div>
      </div>
    </div>
  );
};

export default DisableUser;
