import { list } from './list';

export const lazyLoadImage = (path, ext) => {
  return import(
    /* webpackMode: "lazy-once" */
    `${path}`
  )
    .then(src => ({
      ext: ext === 'svg' ? 'svg' : 'img',
      icon: src.default || '',
    }))
    .catch(err => console.error(err));
};

export const getIconByName = async ({ name, type }) => {
  let path = './icons/all/light.svg';

  const imgName = list[name][type];

  const regExp = /(?:\.([^.]+))?$/;
  const regExpMatches = regExp.exec(imgName);
  const ext = regExpMatches ? regExpMatches[1] : '';

  if (name === 'goldenrace') path = `./icons/golden_race/${imgName}`;
  else path = `./icons/${name}/${imgName}`;

  const result = await lazyLoadImage(path, ext);

  return result;
};
