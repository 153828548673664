import { addNotify } from 'features/notify';
import dayjs from 'dayjs';
import { shortLanguages } from 'services/locale';
import { getErrorMessage } from 'shared/helpers';

const actionTypes = {
  ACTION_PROCESSING: 'finance/ACTION_PROCESSING',
  GET_FINANCE_HISTORY: 'finance/GET_FINANCE_HISTORY',
  GET_FLAT_PAGES: 'finance/GET_FLAT_PAGES',
};

function getFinanceHistory(form) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;
    const { selectedAgentId } = getState().tree;

    const requestedBody = {
      date_from: dayjs(form.beginDate).format('YYYY-MM-DD'),
      date_to: dayjs(form.endDate).format('YYYY-MM-DD'),
      page: form.page,
      count: form.count,
      only_user_transfers: form.onlyUserTransfers,
      only_personal_higher_transfers: form.myOperations,
      is_deposit: form.onlyDeposits,
      is_withdrawal: form.onlyWithdrawals,
    };
    if (selectedAgentId) {
      const response = await api.finance.getFinanceHistory({ id: selectedAgentId, requestedBody });
      if (response.success) {
        dispatch({
          type: actionTypes.GET_FINANCE_HISTORY,
          payload: response.data,
        });
      } else {
        const errorMessage = getErrorMessage(locale, response);
        dispatch(addNotify(errorMessage, 'error'));
      }
    } else return;
  };
}

function getFlatPages(form) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { lang } = getState().locale;
    const { locale } = getState().locale;


    const response = await api.finance.getFlatPages({ lang: shortLanguages[lang] });
    if (response.success) {
      dispatch({
        type: actionTypes.GET_FLAT_PAGES,
        payload: response.data,
      });
    } else {
      const errorMessage = getErrorMessage(locale, response);
      dispatch(addNotify(errorMessage, 'error'));
    }

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  };
}

export { actionTypes, getFinanceHistory, getFlatPages };
