import React, { useCallback, useEffect, useMemo, useState } from 'react';
import block from 'bem-cn';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import SVG from 'components/SVG';

import { actions } from 'features/tree';
import { selectUserInfoById } from 'features/users/redux';

import arrowSvg from './img/arrow.svg';
import userSvg from './img/user.svg';
import InformationTreeRow from './InformationTreeRow';

import './InformationTree.scss';

const b = block('information-tree');

const InformationTree = ({ callback, isMobile }) => {
  const dispatch = useDispatch();
  const usersDataList = useSelector(state => state.tree.users);
  const isFirstParentOpened = !!usersDataList.length && !!usersDataList[0].isOpened;
  const [isOpened, setOpened] = useState(false);
  const selectedAgentId = useSelector(state => state.tree.selectedAgentId);
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const selectAgent = useCallback(
    id => {
      dispatch(actions.selectAgentId(id));
    },
    [dispatch],
  );

  const { userId } = useParams();

  const agentInfo = useSelector(selectUserInfoById(userId));

  const rows = useMemo(
    () =>
      usersDataList.map((row, idx, arr) => (
        <InformationTreeRow
          key={row.userId}
          nesting={[row.userId]}
          row={row}
          isLast={arr.length - 1 === idx}
          idx={idx}
          level={1}
          userList={arr}
        />
      )),
    [usersDataList],
  );

  useEffect(() => {
    isFirstParentOpened && setOpened(isFirstParentOpened);
  }, [isFirstParentOpened]);

  useEffect(() => {
    if (!isMobile) {
      dispatch(actions.getUsersByUserId({ id: userId, nesting: [], isRoot: true }));
      selectAgent(userId);
    }
  }, [isMobile, dispatch, userId, selectAgent]);

  const onClick = useCallback(() => {
    selectAgent(userId);
    if (usersDataList.length !== 0) {
      setOpened(prevState => !prevState);
    } else {
      setOpened(false);
    }
  }, [usersDataList.length, userId, selectAgent]);

  const active = selectedAgentId === userId;

  const CurrentAgent = (
    <div className={b('current-agent')} onClick={onClick}>
      <div className={b('current-agent', 'wrapper')}>
        <SVG svgProps={{ svg: userSvg }} className={b('current-agent', 'img-user', { opened: isOpened, active })} />
        <span className={b('current-agent', 'title', { opened: isOpened, active })}>
          {`${agentInfo.username} (${locale.currentUser})`}
        </span>
      </div>
      {!!usersDataList.length && (
        <SVG svgProps={{ svg: arrowSvg }} className={b('current-agent', 'img-arrow', { opened: isOpened, active })} />
      )}
    </div>
  );

  return (
    <div className={b({ active, isMobile })}>
      {usersDataList[0]?.userId !== userId && CurrentAgent}
      {isOpened && <div className={b('wrapper')}>{rows}</div>}
    </div>
  );
};

InformationTree.propTypes = {};

export default InformationTree;
