import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import TableRow from './TableRow/TableRow';

import './TableProviders.scss';

const b = block('reports-table-providers');

const TableProviders = ({ thItems, trItems }) => {
  const locale = useSelector(state => state.locale.locale);
  const rows = useMemo(() => trItems?.map(item => <TableRow items={Object.values(item)} />), [trItems]);

  const headers = thItems.length && Object.keys(thItems[0]);
  return (
    <div className={b()}>
      <div className={b('header')}>{locale.byProvider}</div>
      <table className={b('table')}>
        {!!trItems.length ? (
          <>
            <div className={b('table-header')}>
              {headers.map(head => (
                <div className={b('table-head')}>{locale[head]}</div>
              ))}
            </div>
            <div className={b('table-body')}>{rows}</div>
          </>
        ) : (
          <div className={b('no-results')}>{locale.noResults}</div>
        )}
      </table>
    </div>
  );
};

TableProviders.propTypes = {};

export default TableProviders;
