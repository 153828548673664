import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import SearchInput from 'components/SearchInput';
import Button from 'components/Button';
import InputDate from 'components/InputDate/desktop';

import useTree from 'hooks/useTree';

import DaysFilter from 'features/reports/view/desktop/DaysFilter';
import { actions } from 'features/reports/redux';

import './FilterBlock.scss';

const b = block('filter-block-player-detail');

const FilterBlock = ({ count, currentPage, onPageClick, date, setDate }) => {
  const locale = useSelector(state => state.locale.locale);
  const dispatch = useDispatch();
  const { getTreeById } = useTree();

  const [activeDay, setActiveDay] = useState('');
  const [filters, setFilters] = useState({
    user: '',
  });
  const [isDirectOnly, setIsDirectOnly] = useState(false);
  const [searchResults, setSearchResults] = useState(null);

  const onChangeBeginDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, beginDate: value });
    }

    setActiveDay('');
  };

  const onChangeEndDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, endDate: value });
    }
  };
  const handleSearchUser = useCallback(
    async value => {
      setFilters({ ...filters, user: value.toLowerCase() });
    },
    [filters],
  );

  const onClickSearch = withoutZeroPage => {
    getTreeById(filters.user || '').then(upperAgent => handleSearch(upperAgent));
    if (!withoutZeroPage) onPageClick(0);
  };

  const handleSearch = upperAgent => {
    const requestedData = {
      isDirectOnly,
      username: filters.user,
      upperAgentUsername: upperAgent && upperAgent.username,
      count,
      page: currentPage,
      ...date,
    };
    dispatch(actions.getStatisticPlayer(requestedData));
  };

  const onChangeActiveDay = useCallback(dayFilter => {
    setActiveDay(dayFilter);
    setDate({
      ...date,
      beginDate: new Date(dayjs().add(dayFilter.value, 'day')),
      endDate: dayFilter.value === -1 ? new Date(dayjs()) : new Date(dayjs().add(1, 'day')),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onClickSearch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, currentPage]);

  return (
    <div className={b()}>
      <div className={b('header')}>{locale.filter}</div>
      <div className={b('top')}>
        <DaysFilter activeDay={activeDay} setActiveDay={onChangeActiveDay} />
        <div className={b('or')}>or</div>
        <div className={b('date')}>
          <div className={b('date-row')}>
            <div className={b('title')}>{locale.dateFrom}</div>
            <div className={b('date-input')}>
              <InputDate selected={date.beginDate} onChange={onChangeBeginDate} />
            </div>
          </div>
          <div className={b('date-row')}>
            <div className={b('title')}>{locale.dateTo}</div>
            <div className={b('date-input')}>
              <InputDate selected={date.endDate} onChange={onChangeEndDate} />
            </div>
          </div>
        </div>
      </div>
      <div className={b('bottom')}>
        <div className={b('inputs')}>
          <div className={b('input')}>
            <div className={b('title')}>{locale.user}</div>
            <SearchInput
              value={filters.username}
              items={searchResults}
              onChange={handleSearchUser}
              placeholder={`${locale.search} ${locale.user}`}
            />
          </div>
        </div>
        <div className={b('button')}>
          <Button size="low" callBack={() => onClickSearch()} text={locale.acceptFilter} />
        </div>
      </div>
    </div>
  );
};

export default FilterBlock;
