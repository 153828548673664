import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import dayjs from 'dayjs';

import SVG from 'components/SVG';

import { formatNumberWithLimit } from 'shared/helpers/formatNumber';

import arrowSVG from './img/arrow-right.svg';

import './TableRow.scss';

const TableRow = ({ item, locale }) => {
  const b = block('table-row-cash');

  return (
    <div className={b('tr')}>
      <div className={b('td')}>
        <span className={b('td-el')}>{item.id}</span>
      </div>
      <div className={b('td')}>
        <span className={b('td-el', { type: 'date' })}>
          {item.createdAt ? dayjs(item.createdAt).format('DD.MM.YY HH:mm:ss') : ''}
        </span>
      </div>
      <div className={b('td')}>
        <span className={b('td-el')}>{item.operation === 0 ? locale.deposit : locale.withdrawal}</span>
      </div>
      <div className={b('td')}>
        <span className={b('td-el')}>{item.initiatorUser}</span>
      </div>
      <div className={b('td')}>
        <span className={b('td-el')}>{item.fromUser}</span>
      </div>
      <div className={b('td')}>
        <span className={b('td-el')}>{item.toUser}</span>
      </div>
      <div className={b('td')}>
        <span className={b('td-el')}>{formatNumberWithLimit(item.amount)}</span>
      </div>
      <div className={b('td')}>
        {formatNumberWithLimit(item.balanceFrom)}
        <SVG svgProps={{ svg: arrowSVG }} className={b('arrow')} />
        {formatNumberWithLimit(item.balanceTo)}
      </div>
    </div>
  );
};

TableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TableRow;
