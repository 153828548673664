import React, { useEffect, useMemo, useState } from 'react';
import block from 'bem-cn';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'components/Button';
import SVG from 'components/SVG';
import { actions } from 'features/users/redux';
import { userTypes } from 'features/users/data';
import { usePermissionDetails } from 'features/users/hooks/usePermissionDetails';
import { formatNumberWithLimit } from 'shared/helpers/formatNumber';

import PermissionDetailsItem from './PermissionDetailsItem/PermissionDetailsItem';
import PermissionDetailsAdvanced from './PermissionDetailsAdvanced/PermissionDetailsAdvanced';

import './PermissionDetails.scss';

const b = block('permission-details');

const PermissionDetails = () => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { userInfo: user } = useSelector(state => state.users);
  const { userId } = useParams();
  const history = useHistory();
  const [advancedPage, changeAdvancedPage] = useState({
    isOpen: false,
    permissionItem: {},
  });
  const { permissions, handleSetPermissions, changeBooleanPermissionsField } = usePermissionDetails(userId);

  const closePage = () => {
    history.goBack();
  };

  const handleAdvancedPage = (item = {}) => {
    changeAdvancedPage(data => ({ ...data, isOpen: !data.isOpen, permissionItem: item }));
  };

  useEffect(() => {
    dispatch(actions.getUserInfo({ id: userId })).then(() => dispatch(actions.getUserPermissions(userId)));
    return () => dispatch(actions.closePermissions());
  }, []);

  const handleGetPermissionsByType = key => {
    dispatch(actions.getUserPermissionsByCasinoType({ userId, type: key }));
  };

  const items = useMemo(
    () =>
      Object.entries(permissions).map(([key, { providers, isOpen, ...item }], idx) => (
        <div className={b('item')}>
          <PermissionDetailsItem
            user={user}
            item={item}
            key={key + idx}
            locale={locale}
            callback={() => !isOpen && handleGetPermissionsByType(key)}
            openAdvancedWindow={handleAdvancedPage}
            changeBooleanPermissionsField={changeBooleanPermissionsField}
            handleSetPermissions={handleSetPermissions}
          />
          <div className={b('item-children')}>
            {!!providers.length &&
              isOpen &&
              providers.map((item, idx) => (
                <PermissionDetailsItem
                  user={user}
                  item={item}
                  key={100 + idx}
                  locale={locale}
                  openAdvancedWindow={handleAdvancedPage}
                  handleSetPermissions={handleSetPermissions}
                />
              ))}
          </div>
        </div>
      )),
    [permissions, locale, user],
  );
  if (advancedPage.isOpen)
    return (
      <PermissionDetailsAdvanced
        permissionItem={advancedPage.permissionItem}
        title={advancedPage.permissionItem.locale}
        close={handleAdvancedPage}
        locale={locale}
        user={user}
      />
    );

  return (
    <div className={b()}>
      <div className={b('form')}>
        <div className={b('header')}>{locale.permissionsDetails}</div>
        <div className={b('user-block')}>
          <div className={b('user-block-left')}>
            <SVG svgProps={{ svg: userTypes[user.role].icon }} className={b('user-block-icon')} />
            <div className={b('user-block-info')}>
              <span>{user.username}</span>
              <div className={b('user-block-info-type', { role: user.role })}>{userTypes[user.role].type}</div>
            </div>
          </div>
          <div className={b('user-block-right')}>
            <span>ID:</span>
            <span>{userId}</span>
          </div>
          <div className={b('user-block-right')}>
            <span>{locale.balance}:</span>
            <span>{formatNumberWithLimit(user.balance)}</span>
          </div>
        </div>
        <div className={b('items')}>{items}</div>
        <div className={b('bottom')}>
          <div className={b('button')}>
            <Button callBack={closePage} text={locale.cancel} size="low" color="full-transparent" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermissionDetails;
