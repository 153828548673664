const actionTypes = {
  CHANGE_MENU: 'app/CHANGE_MENU',
};

const changeSideMenu = () => async (dispatch, getState, extra) => {
  dispatch({
    type: actionTypes.CHANGE_MENU,
  });
};

export { changeSideMenu, actionTypes };
