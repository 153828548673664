import React, { useState, useEffect } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';

import argentina from './img/argentina.png';
import CountryCodeSelector from './CountryCodeSelector/CountryCodeSelector';

import './PhoneInput.scss';

export const phoneCodes = {
  ARGENTINA: {
    code: '+54',
    img: argentina,
    length: 10,
  }, 
};

const b = block('phone-input');

const PhoneInput = ({
  phone,
  countryCode,
  changePhoneNumber,
  readonly
}) => {
  const [state, setState] = useState({
    countryCode: 'ARGENTINA',
    phone: '',
  });

  useEffect(() => {
    const countryCodeEntry = Object.entries(phoneCodes).find(([key, value]) => value.code === countryCode);
    if (!state.phone && phone) setState(prevState => ({ ...prevState, phone }));
    if (countryCode && countryCodeEntry) setState(prevState => ({ ...prevState, countryCode: countryCodeEntry[0] }));
  }, [phone, countryCode, state.phone]);

  const onChangeCountryCode = value => {
    if (readonly) {
      return
    }
    setState(prevState => ({ ...prevState, countryCode: value }));
  };

  const onChangePhoneListener = e => {
    if (readonly) {
      return
    }
    const input = e.currentTarget.value;
    if (!input.split('').some(char => isNaN(char) || char === ' ')) {
      setState(prevState => ({ ...prevState, phone: input }));
      changePhoneNumber && changePhoneNumber(input);
    }
  };

  const { countryCode: selectedCountryCode, phone: phoneNumber } = state;

  return (
    <div className={b()}>
      <div className={b('selector')}>
        <CountryCodeSelector
          phoneCodes={phoneCodes}
          activeValue={selectedCountryCode}
          callBack={onChangeCountryCode}
        />
      </div>
      <input
        className={b('input')}
        maxLength={phoneCodes[selectedCountryCode].length}
        value={phoneNumber}
        name="phone"
        onChange={onChangePhoneListener}
      />
    </div>
  );
};

PhoneInput.propTypes = {
  phone: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  changePhoneNumber: PropTypes.func.isRequired,
};

export default PhoneInput;
