import { compose, applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import persistState from 'redux-localstorage';

import { reducer as appReducer } from './features/app';
import { reducer as localeReducer } from './features/locale';
import { reducer as authReducer } from './features/Auth';
import { reducer as notifyReducer } from './features/notify';
import { reducer as users } from './features/users/redux';
import { reducer as treeReducer } from './features/tree';
import { reducer as reports } from './features/reports/redux';
import { reducer as finance } from './features/finance';
import { reducer as userSettingsReducer } from './features/userSettings';

function configureStore(extra) {
  const middlewares = [thunk.withExtraArgument(extra)];

  const reducer = createReducer();

  // TODO: отключить devtools в production
  const store = createStore(
    reducer,
    compose(
      applyMiddleware(...middlewares),
      persistState(['userSettings', 'auth']),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true }) : arg => arg,
    ),
  );

  return store;
}

function createReducer() {
  return combineReducers({
    app: appReducer,
    locale: localeReducer,
    auth: authReducer,
    notify: notifyReducer,
    users: users,
    tree: treeReducer,
    reports: reports,
    finance: finance,
    userSettings: userSettingsReducer,
  });
}

export { createReducer };
export default configureStore;
