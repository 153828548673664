import React, { useMemo } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import SVGInline from 'react-svg-inline';

import SVG from 'components/SVG';

import { actions as authActions } from 'features/Auth';

import { formatNumberWithLimit } from 'shared/helpers/formatNumber';

import { items } from './data';
import logoutSVG from './img/logout.svg';
import userSVG from './img/user.svg';

import './ProfileBlock.scss';

const b = block('profile-block');

const ProfileBlock = () => {
  const locale = useSelector(state => state.locale.locale);
  const auth = useSelector(state => state.auth);

  const logout = () => {
    dispatch(authActions.logOut());
  };

  const itemsList = useMemo(
    () =>
      items.map(item => (
        <Link key={item.name} className={b('item')} to={`${item.link}/${auth.id}`}>
          <SVG className={b('item-icon').toString()} svgProps={{ svg: item.svg }} />
          <span className={b('item-value')}>{locale[item.title]}</span>
        </Link>
      )),
    [auth.id, locale],
  );

  const dispatch = useDispatch();
  return (
    <div className={b()}>
      <div className={b('left')}>
        <div className={b('user')}>
          <SVGInline svg={userSVG} className={b('user-img').toString()} />
          <div className={b('user-block', { first: true })}>
            <span>{locale.login}</span>
            <span>{auth.username}</span>
          </div>
        </div>

        <div className={b('user-block')}>
          <span>ID</span>
          <span>{auth.id}</span>
        </div>

        <div className={b('user-block')}>
          <span>{locale.balance}:</span>
          <span>{formatNumberWithLimit(auth.balance)}</span>
        </div>
      </div>

      <div className={b('right')}>
        {itemsList}
        <div className={b('item')} onClick={logout}>
          <SVG className={b('item-icon').toString()} svgProps={{ svg: logoutSVG }} />
          <span className={b('item-value')}>{locale.logout}</span>
        </div>
      </div>
    </div>
  );
};

export default ProfileBlock;
