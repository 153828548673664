import React, { useMemo, useState } from 'react';
import block from 'bem-cn';

import Button from 'components/Button';
import SVG from 'components/SVG';
import Switcher from 'components/Switcher';
import dropdownSVG from 'features/users/img/dropdown.svg';
import Spinner from 'components/Spinner/desktop';

import { usePermissionDetails } from 'features/users/hooks/usePermissionDetails';

import './PermissionDetailsAdvanced.scss';
import { useSelector } from 'react-redux';

const b = block('permission-details-advanced');

const PermissionDetailsAdvanced = ({ close, locale, user, permissionItem }) => {
  const [localActive, setLocalActive] = useState(permissionItem.isActive);
  const { actionProcessing } = useSelector(state => state.users);

  const { permissions, handleSetPermissions, changeBooleanPermissionsField } = usePermissionDetails(user.id);

  const { type, provider } = permissionItem;

  const item = useMemo(
    () => (provider ? permissions[type].providers.find(el => el.provider === provider) : permissions[type]),
    [permissions, provider, type],
  );

  const onSubmit = () => {
    handleSetPermissions({ type, provider, isActive: localActive });
  };

  return (
    <div className={b()}>
      <div className={b('form')}>
        <Spinner isLoading={actionProcessing} />
        <div className={b('permission-edit-header')}>
          <SVG svgProps={{ svg: dropdownSVG }} className={b('permission-edit-icon')} onClick={close} />
          <div>{locale.changePermissions(type)}</div>
        </div>
        <div className={b('inputs')}>
          <div className={b('header-info')}>
            <div className={b('header-info-title', { fullWidth: true })}>
              {locale.showTheSelectedCategoryForUser}
              <div className={b('header-info-switcher', { noGap: true })}>
                <Switcher isActive={localActive} onSwitch={() => setLocalActive(!localActive)} />
              </div>
            </div>
          </div>
          <div className={b('header-info')}>
            <div className={b('header-info-title', { fullWidth: true })}>
              {locale.copyPermissions}
              <div className={b('header-info-switcher', { noGap: true })}>
                <Switcher
                  isActive={item.changeSubstructure}
                  onSwitch={() => changeBooleanPermissionsField({ type, fieldName: 'changeSubstructure', provider })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={b('bottom')}>
          <div className={b('buttons')}>
            <div className={b('button')}>
              <Button callBack={close} text={locale.cancel} size="low" color="full-transparent" />
            </div>
          </div>
          <div className={b('button')}>
            <Button callBack={onSubmit} text={locale.save} size="low" color="modalBlue" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermissionDetailsAdvanced;
