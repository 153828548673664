import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';
import { useHistory, useParams } from 'react-router-dom';

import Button from 'components/Button';
import Input from 'components/Input';
import SVG from 'components/SVG';
import Spinner from 'components/Spinner/view/desktop';

import { actions } from 'features/users/redux';

import { validatePassword } from 'shared/helpers/validatePassword';

import { userTypes } from '../../../../data';

import './ChangePassword.scss';

const b = block('change-password-users');

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { id: agentId } = useSelector(state => state.auth);
  const history = useHistory();
  const locale = useSelector(state => state.locale.locale);
  const { userInfo, actionProcessing } = useSelector(state => state.users);
  const { passwordSettings } = useSelector(state => state.userSettings);

  const { username, role } = userInfo;

  const isAgent = useMemo(() => +userId === agentId, [agentId, userId]);

  useEffect(() => {
    if (!isAgent) dispatch(actions.getUserInfo({ id: userId }));
  }, [dispatch, isAgent, userId]);

  const [form, setForm] = useState({
    newPassword: '',
    oldPassword: '',
    confirmNewPassword: '',
  });

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      dispatch(
        actions.changePassword({
          data: { password: form.newPassword },
          id: userId,
          callback: () => history.push('/users/all'),
        }),
      );
    },
    [dispatch, form.newPassword, userId, history],
  );

  const changeValue = useCallback(
    e => {
      setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value?.trim() });
    },
    [form],
  );

  const currentPasswordSettings = useMemo(() => isAgent ? passwordSettings.agent : passwordSettings.player, [passwordSettings, isAgent]);

  const isOldPasswordValid = useMemo(() => {
    if(!isAgent) return true;
    return validatePassword(form.oldPassword, currentPasswordSettings) && form.oldPassword !== '';
  }, [form.oldPassword, currentPasswordSettings, isAgent]);

  const isNewPasswordValid = useMemo(() => {
    return validatePassword(form.newPassword, currentPasswordSettings) && form.newPassword !== '';
  }, [form.newPassword, currentPasswordSettings]);

  const isConfirmPasswordValid = useMemo(() => {
    return validatePassword(form.newPassword, currentPasswordSettings)
      && form.confirmNewPassword === form.newPassword;
  }, [form.confirmNewPassword, form.newPassword, currentPasswordSettings]);

  const disabled = !(isOldPasswordValid && isNewPasswordValid && isConfirmPasswordValid);

  if (actionProcessing) return <Spinner isLoading={actionProcessing} />;

  return (
    <div className={b()}>
      <form className={b('form')} onSubmit={onSubmit}>
        <div className={b('header')}>{locale[isAgent ? 'changeMyPassword' : 'changePassword']}</div>
        <div className={b('top')}>

          {!isAgent && (
            <div className={b('user-block')}>
              <div className={b('user-block-left')}>
                <SVG svgProps={{ svg: userTypes[role].icon }} className={b('user-block-icon')} />
                <div className={b('user-block-info')}>
                  <span>{username}</span>
                  <div className={b('user-block-info-type', { role })}>{userTypes[role].type}</div>
                </div>
              </div>
              <div className={b('user-block-right')}>
                <span>ID:</span>
                <span>{userId}</span>
              </div>
            </div>
          )}
          <div className={b('fields')}>
            {isAgent && (
              <div className={b('form', 'input-block')}>
                <span className={b('form', 'input-block-title')}>{locale.oldPassword}</span>
                <div className={b('input')}>
                  <Input
                    placeholder={locale.oldPassword}
                    withEye
                    validState={isOldPasswordValid ? 1 : 3}
                    type="password"
                    onChange={changeValue}
                    value={form.oldPassword}
                    name="oldPassword"
                  />
                </div>
              </div>
            )}
            <div className={b('form', 'input-block')}>
              <span className={b('form', 'input-block-title')}>{locale.newPassword}</span>
              <div className={b('input')}>
                <Input
                  placeholder={locale.newPassword}
                  withEye
                  validState={isNewPasswordValid ? 1 : 3}
                  type="password"
                  onChange={changeValue}
                  value={form.newPassword}
                  name="newPassword"
                />
              </div>
            </div>
            <div className={b('form', 'input-block')}>
              <span className={b('form', 'input-block-title')}>{locale.confirmNewPassword}</span>
              <div className={b('input')}>
                <Input
                  placeholder={locale.confirmNewPassword}
                  withEye
                  validState={isConfirmPasswordValid ? 1 : 3}
                  type="password"
                  onChange={changeValue}
                  value={form.confirmNewPassword}
                  name="confirmNewPassword"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={b('bottom')}>
          <Button color="full-transparent" callBack={history.goBack} text={locale.cancel} size="low" />
          <Button disabled={disabled} type="submit" size="low" text={locale.changePassword} />
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
