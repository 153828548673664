export const initialState = {
  actionProcessing: false,

  financeHistory: {
    transfers: [],
    details: {
      totalCount: 0,
      totalDeposit: 0,
      totalWithdraw: 0,
      deltaBalance: 0,
    },
  },

  flatPages: [],
};
