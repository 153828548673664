import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import block from 'bem-cn';

import SVG from 'components/SVG';
import Switcher from 'components/Switcher';
import Spinner from 'components/Spinner/view/desktop';

import dropListSVG from '../img/drop-list.svg';
import arrowSVG from '../img/arrow.svg';

import './PermissionDetailsItem.scss';

const b = block('permission-details-item');

const PermissionDetailsItem = ({
  item,
  handleSetPermissions,
  changeBooleanPermissionsField,
  user,
  openAdvancedWindow,
  locale,
  callback,
}) => {
  const { type, level, isActive, provider, isOpen } = item;
  const actionProcessing = useSelector(state => state.users.actionProcessing, shallowEqual);

  const handleOpenAdvancedWindow = e => {
    e.stopPropagation();
    openAdvancedWindow(item);
  };
  const getTitle = text => {
    switch (text) {
      case 'slots':
        return locale.casino;
      default:
        return locale[item.locale] ?? item.locale;
    }
  };

  const title = getTitle(item.locale);

  const handleOnClick = () => {
    if (changeBooleanPermissionsField) changeBooleanPermissionsField({ type, fieldName: 'isOpen' });
    if (callback) callback();
  };

  return (
    <div onClick={handleOnClick} className={b({ level, isOpen })}>
      <Spinner isLoading={actionProcessing} />
      {level === 0 && <SVG svgProps={{ svg: arrowSVG }} className={b('arrows-svg')} />}
      <span className={b('title')}>{title}</span>
      <div className={b('switcher')} onClick={e => e.stopPropagation()}>
        <Switcher isActive={isActive} onSwitch={() => handleSetPermissions({ type, provider })} />
      </div>
      {+user.role > 0 && (
        <SVG onClick={handleOpenAdvancedWindow} svgProps={{ svg: dropListSVG }} className={b('drop-list-svg')} />
      )}
    </div>
  );
};

export default PermissionDetailsItem;
