import React, { useCallback, useRef } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import block from 'bem-cn';

import { actions as notifyActions } from 'features/notify';

/* import { ICopyProps } from './types'; */
import './Copy.scss';

const b = block('copy-desktop');

const Copy = ({ children, value } /* : ICopyProps */) => {
  const dispatch = useDispatch();

  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const onCopy = useCallback(() => {
    dispatch(notifyActions.addNotify(locale.copiedSuccessfully, 'success'));
  }, [dispatch, locale]);

  return (
    <CopyToClipboard text={value} onCopy={onCopy}>
      {children}
    </CopyToClipboard>
  );
};

export default Copy;
