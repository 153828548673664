const initialState = {
  passwordSettings: {
    player: {
      active: true,
      min_length: 0,
      digits: {
        amount: 0,
      },
      letters: {
        amount: 0,
        uppercase: 0,
        lowercase: 0,
      },
      special_characters: {
        amount: 0,
      },
    },
    agent: {
      active: true,
      min_length: 0,
      digits: {
        amount: 0,
      },
      letters: {
        amount: 0,
        uppercase: 0,
        lowercase: 0,
      },
      special_characters: {
        amount: 0,
      },
    },
  },
};

export default initialState;
