import React, { useMemo, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import block from 'bem-cn';

import TableRow from '../TableRow/TableRow';
import TableHead from '../TableHead/TableHead';
import Paginator from 'components/Paginator';
import Select from 'components/Select/desktop';

import './Table.scss';

const b = block('financial-reports-table');

const Table = ({ currentPage, onPageClick, onItemsOnPageChanged, thTdItems, totalCount, type = null }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const rows = useMemo(
    () => thTdItems?.map(item => <TableRow type={type} item={item} />),
    [thTdItems, type],
  );
  const itemsOnPageFilters = [
    { value: 10, name: '10' },
    { value: 25, name: '25' },
    { value: 50, name: '50' },
    { value: 100, name: '100' },
  ];
  const [itemsOnPage, setItemsOnPage] = useState({ value: 10, name: '10' });

  const selectItemChangedHandler = itemValue => {
    setItemsOnPage({ value: itemValue, name: itemValue });
    onItemsOnPageChanged(itemValue);
  };

  return (
    <div className={b()}>
      <div className={b('header')}>
        <span className={b('header-left')}>{locale.result}:</span>
        <div className={b('page-count')}>
          <div className={b('page-count-title')}>{locale.elementsOnPage}:</div>
          <div className={b('page-count-select')}>
            <Select paginator items={itemsOnPageFilters} activeItem={itemsOnPage} onChange={selectItemChangedHandler} />
          </div>
        </div>
      </div>
      <div className={b('table')}>
        <TableHead type={type} items={thTdItems.length !== 0 ? Object.keys(thTdItems[0]) : []} locale={locale} />
        <div className={b('tbody')}>
          {!!thTdItems.length ? rows : <div className={b('no-results')}>{locale.noResults}</div>}
        </div>
        <div className={b('paginator-wrapper')}>
          <div className={b('extra-info')}>
            {totalCount} {locale.records}
          </div>
          <Paginator count={totalCount} currentPage={currentPage} onPageClick={onPageClick} itemsOnPage={itemsOnPage} />
        </div>
      </div>
    </div>
  );
};

Table.propTypes = {
  count: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onItemsOnPageChanged: PropTypes.func,
  onPageClick: PropTypes.func.isRequired,
};

export default Table;
