class SettingsApiConverter {
  convertSettings({ data }) {
    const converterData = {
      passwordSettings: data.password_settings,
    };

    return converterData;
  }
}

export default SettingsApiConverter;
