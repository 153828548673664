import { actionTypes } from './actions';
import initialState from './initial';

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_SETTINGS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

export default reducer;
