import { actionTypes } from './actions';
import { initialState } from './initial';

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ACTION_PROCESSING:
      return {
        ...state,
        actionProcessing: action.payload,
      };

    case actionTypes.GET_STATISTIC_PROVIDER:
      return {
        ...state,
        statisticProvider: { ...action.payload },
        actionProcessing: false,
      };

    case actionTypes.GET_PAYMENT_HISTORY:
      return {
        ...state,
        paymentHistory: action.payload,
        actionProcessing: false,
      };

    case actionTypes.GET_USER_STATISTICS:
      return {
        ...state,
        statisticPlayer: action.payload,
        actionProcessing: false,
      };

    case actionTypes.GET_AGENT_PLAYER_STATISTICS:
      return {
        ...state,
        statisticAgentPlayer: action.payload,
        actionProcessing: false,
      };

    case actionTypes.GET_SLOTS_HISTORY:
      return {
        ...state,
        slotsHistory: action.payload,
        actionProcessing: false,
      };

    case actionTypes.GET_BETS_HISTORY:
      return {
        ...state,
        betsHistory: action.payload,
        actionProcessing: false,
      };
    case actionTypes.GET_DETAILS_BET_HISTORY:
      const { id, data } = action.payload;
      const updatedData = state.betsHistory.data.map(item =>
        item.couponId === id ? { ...item, details: data } : item
      );
      return {
        ...state,
        betsHistory: { ...state.betsHistory, data: updatedData },
        actionProcessing: false,
      };

    case actionTypes.SET_PLAYER_USERNAME:
      if (action.payload.role === '6') {
        return {
          ...state,
          agentName: action.payload.username,
        };
      } else {
        return {
          ...state,
          playerName: action.payload.username,
        };
      }

    default:
      return { ...state };
  }
}
