import React, { forwardRef, useEffect, useMemo, useRef } from 'react';
import block from 'bem-cn';
import DatePicker from 'react-datepicker';
import { shallowEqual, useSelector } from 'react-redux';

import SVG from 'components/SVG';

import { formateDate } from 'shared/helpers';

import { getMonth, months, birthYears, getYear } from './../../helpers/index';

import calendarSVG from './img/calendar.svg';
import arrowSVG from './img/arrow.svg';

import 'react-datepicker/dist/react-datepicker.css';

import './InputDate.scss';

const b = block('input-date-desktop');

const InputDate = ({
  dateFormat = 'dd.MM.yyyy',
  selected,
  isValid,
  onChange,
  color,
  withManualTyping = false,
  disabled = false,
  needPadding = false
}) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const datepickerRef = useRef(null);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className={b('custom-date-input', { needPadding })} onClick={onClick} ref={ref}>
      <SVG className={b('custom-date-input-calendar')} svgProps={{ svg: calendarSVG }} />
      <span className={b('custom-date-input-value')}>{formateDate(value)}</span>
      {!disabled && <SVG className={b('custom-date-input-arrow')} svgProps={{ svg: arrowSVG }} />}
    </div>
  ));

  const validState = useMemo(() => {
    if (String(selected).length === 0 || isValid === undefined) return 1;
    if (isValid) return 2;
    return 3;
  }, [isValid, selected]);

  const openDatePicker = () => {
    if (datepickerRef.current) {

      datepickerRef.current.setOpen(true);
    }
  };

  // useEffect(() => {
  //   if (selected && datepickerRef.current) {
  //     datepickerRef.current.setOpen(false);
  //   }
  // }, [selected]);

  return (
    <div className={b({ validState, color, withManualTyping, disabled })}>
      {withManualTyping ? (
        <div className={b('custom-date-input', { special: true, needPadding })} onClick={openDatePicker}>
          <SVG className={b('custom-date-input-calendar', { special: true })} svgProps={{ svg: calendarSVG }} />
          <DatePicker
            disabled={disabled}
            selected={selected}
            onChange={onChange}
            dateFormat={dateFormat}
            ref={datepickerRef}
            renderCustomHeader={
              ({
                date,
                changeYear,
                changeMonth,
              }) => (
                <div
                  style={{
                    margin: 5,
                    display: "flex",
                    gap: '10px'
                  }}
                >
                  <select
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                    style={{
                      width: '100%',
                      padding: '10px'
                    }}
                  >
                    {birthYears.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                    style={{
                      width: '100%',
                      padding: '10px'
                    }}
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {locale[option]}
                      </option>
                    ))}
                  </select>

                </div>
              )
            }
          />
          {!disabled && <SVG className={b('custom-date-input-arrow', { special: true })} svgProps={{ svg: arrowSVG }} />}
        </div>
      ) : (
        <DatePicker
          dateFormat={dateFormat}
          selected={selected}
          onChange={onChange}
          disabled={disabled}
          customInput={<ExampleCustomInput />}
        />
      )}
    </div>
  );
};

export default InputDate;
