import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import DetailsSvg from '../img/details.svg';

const useCashList = item => {
  const locale = useSelector(state => state.locale.locale);
  return useMemo(
    () => [
      { data: item.operation === 0 ? locale.deposit : locale.withdrawal, isRed: true },
      { head: 'ID', data: item.id, notColumn: true, type: 'small' },
      { data: item.createdAt ? dayjs(item.createdAt).format('HH:mm:ss DD.MM.YY') : '', type: 'small' },
      { head: locale.initiator, data: [item?.initiatorUser] },
      { head: locale.from, data: [item?.fromUser] },
      { head: locale.to, data: [item?.toUser] },
      { head: locale.prevBalance, data: item.balanceFrom, isBalance: true },
      { head: locale.afterBalance, data: item.balanceTo },
      { head: locale.amount, data: item.amount },
    ],
    [locale, item],
  );
};

const usePlayerHistoryList = item => {
  const locale = useSelector(state => state.locale.locale);
  return useMemo(
    () => [
      { head: 'ID', data: item.id, notColumn: true },
      { data: item.date },
      { data: locale.statuses[item.status], status: item.status },
      { head: locale.provider, data: item.provider },
      { head: locale.player, data: `${item.player[1]} (ID: ${item.player[0]})` },
      { head: locale.prevBalance, data: item.prevBalance },
      { head: locale.afterBalance, data: item.afterBalance },
      { head: locale.amount, data: item.amount },
    ],
    [locale, item],
  );
};

const usePlayerDetailList = item => {
  const locale = useSelector(state => state.locale.locale);
  return useMemo(
    () => [
      { head: 'ID', data: item.id },
      { head: locale.name, data: item.name },
      { head: 'details-button', data: DetailsSvg },
      { head: locale.amountIn, data: item.amountIn },
      { head: locale.amountOut, data: item.amountOut },
      { head: locale.total, data: item.total },
    ],
    [locale, item],
  );
};

const useSportBetsList = item => {
  const locale = useSelector(state => state.locale.locale);
  return useMemo(
    () => [
      { head: locale.couponId, data: item.couponId },
      { head: locale.userId, data: item.userId },
      { head: locale.date, data: item.date },
      { head: locale.type, data: item.type },
      { head: locale.amount, data: item.amount },
      { head: locale.currency, data: item.currency },
      { head: locale.coef, data: item.totalCoeff },
      { head: locale.possibleGain, data: item.possibleGain },
      { head: locale.status, data: locale[item.status], status: item.status },
    ],
    [locale, item],
  );
};
const useSportBetsListDesktop = item => {
  const items = useMemo(
    () => [item.couponId, item.userId, item.date, item.type, item.amount, /* item.currency, */ item.totalCoeff, item.possibleGain, item.status
    ],
    [item]);

  const headers = useMemo(
    () => ['couponId', 'userId', 'date', 'type', 'amount',/*  'currency', */ 'coef', 'possibleGain', 'status'], []
  )
  return { items, headers }
};


export { useCashList, useSportBetsList, usePlayerHistoryList, usePlayerDetailList, useSportBetsListDesktop };
