import React from 'react';
import block from 'bem-cn';

import SVG from 'components/SVG';

import CheckSVG from './img/check.svg';

import './CheckBox.scss';

const b = block('check-box-desktop');

const CheckBox = ({ checked, name, onChange }) => (
  <label className={b({ check: checked })}>
    {checked && <SVG className={b('check').toString()} svgProps={{ svg: CheckSVG }} />}
    <input className={b('input')} name={name} type="checkbox" checked={checked} onChange={onChange} />
  </label>
);

export default CheckBox;
