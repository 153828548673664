import React, { useMemo, useCallback } from 'react';
import block from 'bem-cn';
import PT from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { actions as authActions } from 'features/Auth';

import crossSVG from 'shared/img/cross.svg';

import NotificationItem from './NotificationItem';

import './NotifiesView.scss';

const b = block('notifies-view');

dayjs.extend(customParseFormat);

const NotifiesView = ({ closeFunction }) => {
  const dispatch = useDispatch();
  const { notifications, agentsFromNotify } = useSelector(state => state.auth, shallowEqual);
  const { locale } = useSelector(state => state.locale, shallowEqual);

  const handleCrossClick = useCallback(
    id => {
      if (id) {
        dispatch(authActions.dropNotifications(id));
      } else {
        closeFunction();
      }
    },
    [closeFunction, dispatch],
  );

  const getAgentName = useCallback(
    fromId => {
      return agentsFromNotify?.find(agent => agent.fromUserId === fromId)?.fromNick || `ID ${fromId}`;
    },
    [agentsFromNotify],
  );

  const items = useMemo(() => {
    return notifications?.map(({ id, date, type, amount, fromId, isNew }) => {
      const fromName = getAgentName(fromId);
      return (
        <NotificationItem
          key={id}
          id={id}
          date={date}
          type={type}
          amount={amount}
          fromName={fromName}
          isNew={isNew}
          locale={locale}
          onCrossClick={handleCrossClick}
        />
      );
    });
  }, [notifications, locale, handleCrossClick, getAgentName]);

  return (
    <div className={b()}>
      <div className={b('header')}>
        <p className={b('title')}>{`${locale.notification}: ${notifications.length}`}</p>
        <SVGInline className={b('cross').toString()} svg={crossSVG} onClick={() => handleCrossClick()} />
      </div>
      <div className={b('items')}>{items}</div>
    </div>
  );
};

NotifiesView.propTypes = {
  closeFunction: PT.func.isRequired,
};

export default NotifiesView;
