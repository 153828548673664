import React, { useMemo } from 'react';
import block from 'bem-cn';
import './TableHead.scss';
import { useSportBetsListDesktop } from '../../../../features/reports/hooks/useLists';

const TableHead = ({ items, locale, type }) => {
  const b = block('table-head-pagination');

  const getFilteredItem = useMemo(() => {
    switch (type) {
      case 'sport-bets':
        return useSportBetsListDesktop;
      default:
        return () => [];
    }
  }, [type]);

  const list = getFilteredItem(items);
  const listItems = list.headers ? list.headers : items;

  return (
    <div className={b()}>
      {listItems?.map(el => (
        <div key={el} className={b('table-head', { type })}>
          {locale[el]}
        </div>
      ))}
    </div>
  );
};

export default TableHead;
